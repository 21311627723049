@import url(https://fonts.googleapis.com/css2?family=Nunito:wght@400;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Nunito:wght@400;600;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* New Kyc form css */


:root {
  --header-height: 100px;
  /* --nav-width: 68px; */
  --first-color: #ffffff;
  /* --first-color-light: #AFA5D9; */
  --white-color: #000000;
  --body-font: "Nunito", sans-serif;
  --normal-font-size: 1rem;
  --z-fixed: 100;
}

*,
::before,
::after {
  box-sizing: border-box;
}

body {
  position: relative;
  margin: var(--header-height) 0 0 0;
  padding: 0 1rem;
  font-family: var(--body-font);
  font-size: var(--normal-font-size);
  transition: 0.5s;
}

a {
  text-decoration: none;
}

.header {
  width: 100%;
  height: var(--header-height);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
  background-color: #ffffff !important;
  z-index: 200;
  transition: 0.5s;
}

.header_toggle {
  color: var(--white-color);
  font-size: 2rem;
  cursor: pointer;
  display: none;
}

.header_img {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  border-radius: 50%;
  overflow: hidden;
}

.header_img img {
  width: 40px;
}

.header_item {
  display: flex;
  justify-content: center;
  overflow: hidden;
}
.welcome_name {
  font-weight: 600;
  font-size: 20px;
  color: #000;
  width: 25rem;
}

.user_heading_div {
  margin-left: 10rem;
}
.img_logo {
  height: 55px;
  margin-left: 20px;
}

.heading_table {
  font-size: 1.2rem;
  font-weight: 600;
}
.nav_logo-name {
  /* color: #804a42;
    font-weight: 600;
    font-size: 30px; */
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 23px;
  color: #000;
  margin-top: 20px;
  margin-left: 10px;
}

.nav_input-name {
  width: 50rem !important;
  height: 3.5rem;
  margin-bottom: 2rem;
}
.input_search {
  width: 35rem;
  height: 3.5rem;
  font-size: 1.5rem !important;
  text-align: left !important;
  padding-left: 1rem;
  border: 1px solid black;
  border-radius: 1rem;
  background-color: #f5f5f5;
  outline: none;
}
.input_search:hover {
  background-color: #fff;
}
.search_icon_img {
  width: 2.5rem;
  height: 2.5rem;
  margin-left: -3rem;
  padding-top: 1rem; 
}
/* .nav-logo {
    background-color: none;
} */
/* .search_logo {
    height: 1.2rem;
     width: auto;
   
} */
/* .btn_status {
    width: 9.4rem;
    height: 2.6rem;
    background: #1240C2;
    border-radius: 16px;
    color: white;
    margin-right: 10px;
} */
/* .btn_status_div {
width: 151px;
height: 43px;
background: #1240C2;
border-radius: 15px;
color: white;
margin-right: 10px;
} */
.btn_create_user {
  background: #1240c2;
  border-radius: 15px;
  width: 186px;
  height: 44px;
  outline: none;
  border: none;
  color: white;
  font-weight: 600;
  font-size: 14px;
  line-height: 29px;
  margin-bottom: 30px;
}
.nav_list {
  margin-top: 120px;
  margin-left: 1rem;
  height : 100%;
}
.l-navbar {
  position: fixed;
  top: 0;
  width: 15rem;
  height : 100%;
  background-color: var(--first-color);
  /* padding: .5rem 1rem 10 10; */
  transition: 0.5s;
  /* z-index: var(--z-fixed); */
  box-shadow: 0px 8px 8px -6px rgba(0, 0, 0, 0.5);
}

.nav {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  box-shadow: 0 2px 2px -2px rgba(0, 0, 0, 0.2);
}

.nav_logo {
  display: grid;
  grid-template-columns: -webkit-max-content -webkit-max-content;
  grid-template-columns: max-content max-content;
  align-items: center;
  grid-column-gap: 1rem;
  column-gap: 1rem;
  padding: 0.5rem 0 0.5rem 1.5rem;
  font-size: 15px;
  font-weight: 700;
}
.nav_link {
  display: grid;
  grid-template-columns: -webkit-max-content -webkit-max-content;
  grid-template-columns: max-content max-content;
  align-items: center;
  grid-column-gap: 1rem;
  column-gap: 1rem;
  padding: 0.5rem 0 0.5rem 1.5rem;
  font-size: 15px;
  font-weight: 700;
}

.nav_logo-icon {
  font-size: 1.25rem;
  color: var(--white-color);
}

.nav_logo:hover {
  color: #000000;
  text-decoration: none !important;
}

.nav_link {
  position: relative;
  color: #1f1f1f;
  margin-bottom: 1rem;
  /* transition: .3s; */
  font-weight: 500;
  font-size: 14px;
  line-height: 29px;
  text-decoration: none;
}
.active {
  color: var(--white-color);
  background-color: #699eff;
  padding: 10px 20px;
  border-radius: 8px;
  margin-left: 5px;
  text-decoration: none;
}
.nav_link:hover {
  color: var(--white-color);
  text-decoration: none;
}

.nav_icon {
  font-size: 1.25rem;
}

/* .show {
    left: 0
}

.body-pd {
    padding-left: calc(var(--nav-width) + 1rem)
} */

/* .active::before {
    content: '';
    position: absolute;
    left: 0;
    width: 2px;
    height: 32px;
    background-color: var(--white-color)
} */

.height-100 {
  height: 100vh;
}
.input_search {
  text-align: center;
  font-size: 1.5rem;
}
.status_div {
  display: flex;
  flex-direction: row;
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .logo_id_div {
    height: 10rem !important;
    width: 12rem;
    margin-top: 4rem;
  }
  .header_img {
    width: 2.5rem;
    height: 2.5rem;
  }

  .header_img img {
    width: 2.7rem;
  }

  .l-navbar {
    left: 0;
    padding: 1rem 1rem 0 0;
    /* width: 12rem; */
  }

  #body-pd {
    /* padding-left: calc(var(--nav-width) + 180px) */
    /* margin-left: 150px;
   margin-top: 20px; */
    margin: 20px 50px 0 180px;
    padding-right: 100px;
    width: 100%;
  }
  .nav_list {
    text-align: center;
    margin-left: 0.5rem;
    height : 100%;
  }
  /* .header_item {
        margin-left: 3rem;
    } */
  .img_logo {
    margin-left: 1rem;
  }
  .nav-logo {
    margin-left: 2rem;
  }
  .welcome_name {
    margin-left: 5rem;
  }
  .header_item h5 {
    margin-left: 3rem;
  }
  .nav_input-name {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 15rem;
    margin-right: 20rem;
  }
  .input_search {
    margin-left: 45rem;
  }
  .welcome_name {
    min-width: 10rem;
    margin-left: 1rem;
  }
  .status_div {
    text-align: center;
  }
  .card_div {
  }
  .total_div {
    margin-top: 2rem;
  }
  .total_status_div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .container1_div {
    margin-top: 5rem;
  }
  .view_btn {
    width: 3rem !important;
  }
  .card_id_div {
    width: 40rem !important;
  }
  .quote_div {
    font-size: 0.8rem !important;
    margin-top: 1rem;
    text-align: center;
  }

  .login_image {
    margin-right: 10rem !important;
    width: 30rem;
    margin-bottom: 10rem;
    height: auto;
    position: relative;
  }
  .body_login_div {
    display: flex;
    margin-left: 5rem;
  }
}

/* // media queries */
@media screen and (min-width: 992px) and (max-width: 1200px) {
  .logo_id_div {
    height: 10rem !important;
    width: 12rem;
    margin-top: 4rem;
  }

  .header_img {
    width: 2.5rem;
    height: 2.5rem;
  }

  .header_img img {
    width: 2.7rem;
  }

  .l-navbar {
    left: 0;
    padding: 1rem 1rem 0 0;
    /* width: 12rem; */
  }

  #body-pd {
    /* padding-left: calc(var(--nav-width) + 180px) */
    /* margin-left: 150px;
   margin-top: 20px; */
    margin: 20px 50px 0 180px;
    padding-right: 100px;
    width: 100%;
  }
  .nav_list {
    text-align: center;
    margin-left: 0.5rem;
    height : 100%;
  }
  /* .header_item {
        margin-left: 3rem;
    } */
  .img_logo {
    margin-left: 1rem;
  }
  .nav-logo {
    margin-left: 2rem;
  }
  .welcome_name {
    margin-left: 5rem;
  }
  .header_item h5 {
    margin-left: 3rem;
  }
  .nav_input-name {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 15rem;
    margin-right: 20rem;
  }
  .input_search {
    margin-left: 25rem;
  }
  .welcome_name {
    min-width: 10rem;
    margin-left: 1rem;
  }
  .container1_div {
    margin-top: 5rem;
  }
  .login_image {
    margin-right: 10rem !important;
    width: 30rem;
    margin-bottom: 10rem;
    height: auto;
  }
}
@media screen and (min-width: 1201px) {
  body {
    margin: calc(var(--header-height) + 1rem) 0 0 0;
    padding-left: calc(var(--nav-width) + 2rem);
  }

  .header {
    height: calc(var(--header-height) + 1rem);
    padding: 0 2rem 0 calc(var(--nav-width) + 2rem);
  }

  .header_img {
    width: 2.5rem;
    height: 2.5rem;
  }

  .header_img img {
    width: 2.7rem;
  }

  .l-navbar {
    left: 0;
    padding: 1rem 1rem 0 0;
    /* width: 12rem; */
  }

  /* .show {
    width: calc(var(--nav-width) + 156px);
  } */

  #body-pd {
    /* padding-left: calc(var(--nav-width) + 180px) */
    /* margin-left: 150px;
   margin-top: 20px; */
    margin: 20px 50px 0 180px;
    padding-right: 100px;
    width: 100%;
  }
  .nav_list {
    text-align: center;
    margin-left: 0.5rem;
    height : 100%;
  }
  /* .header_item {
        margin-left: 3rem;
    } */
  .img_logo {
    margin-left: 1rem;
  }
  .nav-logo {
    margin-left: 2rem;
  }
  .welcome_name {
    margin-left: 5rem;
  }
  .header_item h5 {
    margin-left: 3rem;
  }
  .nav_input-name {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 15rem;
  }
  .input_search {
    margin-left: 25rem;
  }
  .welcome_name {
    min-width: 10rem;
    margin-left: 1rem;
  }
}

@media screen and (min-width: 360px) and (max-width: 767px) {
  .total_div {
    margin-top: 1.5rem;
  }
  .total_status_div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .para_div {
    font-size: 10px;
  }
  .view_btn {
    width: 4rem !important;
    font-size: 10px;
  }
  .btn_status {
    width: 7rem;
    height: 2.6rem;
    background: #1240c2;
    border-radius: 16px;
    color: white;
    margin-right: 10px;
    font-size: 12px !important;
  }
  .card_div {
    width: 65%;
    margin-bottom: 2rem;
  }
  .dis_num {
    font-size: 20px;
  }
  .active {
    background: none;
    text-decoration: none;
  }
  .brand_logo_div {
    font-size: 1.5rem;
    margin-top: -1rem;
  }
  .btn_st_div {
    display: flex;
    flex-direction: column !important;
    width: 10rem;
    margin: auto;
  }
  .btn_status_div4 {
    margin-bottom: 20px !important;
    display: flex;
    justify-content: center !important;
    align-items: center;
  }
  .card_table_div {
    display: flex;
    flex-direction: column !important;
    width: 35rem;
  }
  .card_body_div {
    font-size: 10px;
    display: flex;
    flex-direction: column;
    height: 20rem;
  }
  .heading_table {
    display: flex;
    flex-direction: column !important;
  }
  .card_id_div {
    width: 20rem !important;
    display: flex;
    flex-direction: column !important;
    justify-content: center !important;
  }
  .user_image_div {
    width: 6rem !important;
    height: 6rem !important;
    display: flex;
    margin-left: 5rem;
    margin-bottom: 2rem;
  }
  .quote_div {
    display: flex;
    justify-content: center;
  }
  .logo_id_div {
    width: 12rem;
    height: 9rem;
    margin-top: 10rem;
    margin-left: -5.5rem;
  }
  .login_user_div {
    width: 40rem !important;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 10rem;
  }
  .input_login_form {
    width: 25rem !important;
    height: 2.5rem;
    margin-left: 7rem;
    background: black;
  }
  .label_login_div {
    margin-left: 8rem;
  }
  .submit_login_div {
    width: 25rem !important;
    margin-left: 7rem;
  }
  .body_login_div {
    /* margin-left: 10rem; */
    margin-top: -5rem;
  }
  .valid_login_div {
    width: 25rem;
    margin-left: 8rem !important;
  }
  .login_image {
    display: none;
  }
}


.up-nav {
    font-size: 1.2rem;
    text-decoration: none;
    border: none;
    color: #fff;
}
.up-nav:hover {
    text-decoration: none;
    color: black;
}
.in-nav {
font-size: 1.2rem;
text-decoration: none;
border: none;
color: #fff;
}
.in-nav:hover {
    text-decoration: none;
    color: black;
}
body > div.fade.modal.show > div > div > div.modal-body > div.row.justify-content-center > div > div {
  margin :auto;
  width: inherit;
}
.kyc_container_div {
display: flex;
width: 100%;
justify-content: center;
}
.kycform_div {
    width: 60rem;
    padding: 2rem;
    margin-left: -7rem;
    margin-bottom: 5rem;
    background-color: whitesmoke;
    border-radius: 1rem;
}
.input_div {
    border: none;
    border-radius: 1rem;
    background-color: #fff;
}

.is_name_div {
    font-size: 1.5rem;
    font-weight: 600;
    padding-left: 1.2rem;
}
.btn-div {
    margin-top: 2rem;
    width: 5rem;
}
.btn_div {
    margin-top: 2rem;
    margin-left: 3rem;
    width: 5rem;
    border: none;
    border-radius: 1rem;
}
@media screen and (min-width: 769px) and (max-width: 991px) {
    .kyc_container_div {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .kycform_div {
      width: 40rem;
      margin-left: 10rem;
}
.is_name_div {
    font-size: 1.2rem;
    font-weight: 600;
    padding-left: 1.2rem;
}
}
@media screen and (min-width: 992px) and (max-width: 1200px) {
    .kyc_container_div {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .kycform_div {
        width: 50rem;
        margin-left: 10rem;
  }
}
@media screen and (min-width: 375px) and (max-width: 768px) {
    .kyc_container_div {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 15rem;
    }
    .kycform_div {
        width: 22rem;
        margin-top: 3rem;
        margin-left: 15rem;
  }
}
@media screen and (width: 768px) {
    .kyc_container_div {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 15rem;
    }
    .kycform_div {
        width: 35rem;
        margin-top: 3rem;
        margin-left: 15rem;
  }
}


/* .signup-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 12rem;
} */

/* .number_div {
    input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}
} */

.navbar-light .navbar-toggler {
    color: rgb(0 0 0);
    border-color: rgb(0 0 0 / 0%);
    outline: none;
    left: 10px;
    margin-right: 2rem;
}
/* .nav_div {
    display: flex;
    height: 30rem;
} */

.nav_mobile_div {
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.ul_div {
    height: 150px;
    overflow-y: scroll;
}
